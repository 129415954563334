// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint no-template-curly-in-string: 0*/
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "app-be-advisor-dev-sb-687842713118-uploads",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://l0ujr1llmi.execute-api.eu-central-1.amazonaws.com/dev/v1",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_V0FU08i5R",
    APP_CLIENT_ID: "2dpjvkb5d6svm5i8ldq7sgakdm",
    IDENTITY_POOL_ID: "eu-central-1:879f3ff3-0f50-4119-b3c5-c084040eecea",
  },
  locales: {
    URL: "https://locales.platform-dev-sb.dev.beteiligungsboerse.net",
  },
  datastore: {
    URL: "https://datastore.platform-dev-sb.dev.beteiligungsboerse.net",
  },
  integrations: {
    CALENDLY_TOKEN: "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjc3NjYxNDcwLCJqdGkiOiI2ZTlmYzY4Yi1hZWJhLTRiMzQtYjNlMC1hMGQ0NzZmNWNjNzAiLCJ1c2VyX3V1aWQiOiJkNjljMTA4ZS0wNWIxLTRmYTktYWM3ZS1iMDFjMzZhZjUxYzcifQ.YqPhuktnuWOINLsxaMvx7ykTlNjzx5dTVas2rCIw7bZXXh3jimShVTfOOIuvbMujkPXnScfz8ErrIpisrrLyLQ",
    CALENDLY_URL: "https://calendly.com/beteiligungsboerse-dev/30min?hide_gdpr_banner=1",
  },
  registration: {
    URL: "https://registration.platform-dev-sb.dev.beteiligungsboerse.net/v1",
    NEXT_PAGE: "https://app.advisor-dev-sb.dev.beteiligungsboerse.net",
    TO_REFERRER: "https://www.beteiligungsboerse.eu/register-success/",
  },
  product: {
    PRODUCT_KEY: "advisor",
  },
};

export default config;
