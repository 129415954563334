/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
  /**
   * @memberof configuration
   * @property {object}webapp - Webapp defaults.
   */
  webapp: {
    MAX_ATTACHMENT_SIZE: 5000000,

    APP_API_ID: "app-be",
    NOTES_API_ENDPOINT: "/notes",
    ACCOUNT_API_ENDPOINT: "/account",
    CLIENT_API_ENDPOINT: "/clients",
    USER_API_ENDPOINT: "/users",
    EVENT_API_ENDPOINT: "/events",
    TRANSACTION_API_ENDPOINT: "/transactions",
    INVESTOR_API_ENDPOINT: "/investors",
    ADVISOR_API_ENDPOINT: "/advisors",
    FILE_API_ENDPOINT: "/files",
    CUSTOMER_API_ENDPOINT: "/customers",
    DOCUMENTS_API_ENDPOINT: "/documents",
    METADATA_API_ENDPOINT: "/metadata",
    SIGNUP_API_ID: "registration",
    FORMS_API_ENDPOINT: "/forms",
    EMAILS_API_ENDPOINT: "/emails",
    COUPON_API_ENDPOINT: "/coupons",
    INVESTORS_API_ENDPOINT: "/investors",
    INVESTMENT_TEASER_FILE_API_ENDPOINT: "/public",
    INVESTMENT_TEASER_INVITE_API_ENDPOINT: "/invites",
    LOGINS_API_ENDPOINT: "/logins",
    WARMUP_API_ENDPOINT: "/warmup,",
  },

  /**
   * @memberof configuration
   * @property {object}file - File definitions
   */
  file: {
    URN: {
      EVAL_FINANCIAL_CANEI: "eval_financial_canei",
      EVAL_REGISTER_PALTURAI: "eval_register_palturai",
      FILE: "file",
      AVV: "avv",
      CONTRACT_ADVISOR: "contract_advisor",
      CONTRACT_COMPANY: "contract_company",
      CONTRACT_SPA: "contract_spa",
      CONTRACT_SHA: "contract_sha",
      DD_PLANING_CANEI: "dd_planing_canei",
      DD_LEGAL_PMASON: "dd_legal_pmason",
      IMAGE: "image",
      INVEST_FACTBOOK: "invest_factbook",
      INVEST_TEASER: "invest_teaser",
      INVOICE_1: "invoice_1",
      INVOICE_2: "invoice_2",
      INVOICE_FINAL: "invoice_final",
      LOGO: "logo",
      NDA_COMPANY: "nda_company",
      NDA_INVESTOR: "nda_investor",
      PR_RELEASE: "pr_release",
      PROFILE: "profile",
      SUSA: "susa",
    },
    ATTRIB: {
      SUSA_DATE: "susa_date",
      FILE_ID: "file_id",
    },
  },

  /**
   * @memberof configuration
   * @property {object}record - Record definitions
   */
  record: {
    URN: {
      TRANSACTION: "transaction",
    },
    FILES: {
      MIME_TYPES: {
        "text/csv": "csv",
        "image/jpeg": "jpeg",
        "image/png": "png",
        "application/pdf": "pdf",
        "application/msword": "doc",
        "application/vnd.ms-excel": "xls",
        "application/vnd.ms-powerpoint": "ppt",
        "application/vnd.visio": "vsd",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
      },
    },
  },

  /**
   * @memberof configuration
   * @property {object}app_event - App event
   */
  app_event: {
    TYPES: {
      LOGIN: "login",
    },
    URN: {
      WEBAPP: "webapp",
    },
  },

  /**
   * @memberof configuration
   * @property {object}shared_links - Shared links.
   */
  shared_links: {
    IMPRINT: "https://companylinks.com/impressum/",
    TERMS_URL: "https://companylinks.com/allgemeine-geschaeftsbedingungen/",
    PRIVACY_URL: "https://companylinks.com/datenschutzerklaerung/",
    PIPEDRIVE_URL: "https://beteiligungsboersedeutschland.pipedrive.com/deal/",
  },

  /**
   * @memberof configuration
   * @property {object}communication - communication settings.
   */
  communication: {
    phone_number: "+4917673553103", // for a call, used in PersonalTalk component
  },

  /**
   * @memberof configuration
   * @property {object}product - product settings.
   */
  defaults: {
    PRODUCT_ID: "advisor",
    PRICING_ID: "advisor_01",
  },
};

export default config;
